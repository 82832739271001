import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostLayout from "../layouts/post-layout.tsx";
import { Link } from "gatsby";
export const _frontmatter = {
  "title": "Contact",
  "path": "/contact"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PostSeoComponent = makeShortcode("PostSeoComponent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PostSeoComponent title='Contact' path='/contact' mdxType="PostSeoComponent" />
    <h2>{`Looking for a chat?`}</h2>
    <p>{`You can get in touch with me over LinkedIn (`}<Link to="https://www.linkedin.com/in/max-bause-8357b718a/" target="_blank" mdxType="Link">{`Max Bause`}</Link>{`) or Instagram (`}<Link to="https://www.instagram.com/maxbse/" target="_blank" mdxType="Link">{`@maxbse`}</Link>{`).`}</p>
    <h3>{`Git socials`}</h3>
    <ul>
      <li parentName="ul">{`GitLab: `}<Link to="https://gitlab.com/users/maxbause" target="_blank" mdxType="Link">{`@maxbause`}</Link></li>
      <li parentName="ul">{`GitHub: `}<Link to="https://github.com/maxbause" target="_blank" mdxType="Link">{`@maxbause`}</Link></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      